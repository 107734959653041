<!--开发票-->
<template>
  <div class="invoice_box">
    <headSearch ref="headSearch" :search-out="headSearch" />
    <div class="invoice_box_list top">
      <div class="invoice_box_title">
        <i class="invoice_box_l">需要发票</i>
      </div>
    </div>
    <div class="invoice_box_span">发票金额为实际支付金额(不包括优惠券和立减金额)</div>
    <div class="invoice_box_list">
      <div class="invoice_box_title">
        <i class="invoice_box_l">发票类型</i>
        <div class="invoice_box_r">
          <span>{{ list.typeText == 1 ? '纸质发票' : '电子发票' }}</span>
        </div>
      </div>
    </div>

    <div class="invoice_box_list top">
      <div class="invoice_box_title">
        <i class="invoice_box_l">抬头类型</i>
        <em :class="list.titleType==1 ? 'em':''">公司</em>
        <em :class="list.titleType==2 ? 'em':''">个人</em>
      </div>
    </div>
    <div class="invoice_box_list">
      <div class="invoice_box_title">
        <i class="invoice_box_l">发票抬头：{{ this.$store.state.title }}</i>
        <img src="../../assets/images/MoreIocn.png" alt="" @click="linkTo(1)">
      </div>
    </div>

    <div class="invoice_box_list">
      <div class="invoice_box_title">
        <i class="invoice_box_l">纳税人识别号：</i>
      </div>
    </div>

    <div class="invoice_box_list top">
      <div class="invoice_box_title no">
        <i class="invoice_box_l">发票内容：</i>
        <span class="span">商品明细</span>
      </div>
    </div>

    <div class="invoice_box_list top">
      <div class="invoice_box_title no">
        <i class="invoice_box_l">邮箱：</i>
        <span class="span">{{ list.email }}</span>
        <img src="../../assets/images/MoreIocn.png" alt="" @click="linkTo(22)">
      </div>
    </div>

  </div>
</template>

<script>
import headSearch from '@/component/head/search'
import { invoiceInfo } from '@/services/api'

export default {
  name: 'Invoice',
  components: {
    headSearch
  },
  data() {
    return {
      list: '',
      headSearch: {
        isShowSearch: false,
        isShowReturn: true,
        bgColor: '#fff', // 背景颜色
        titleData: '填写发票信息',
        callbackGo: this.callbackGo, // 回调
        callbackRefresh: this.callbackRefresh,
        btnRefresh: true,
        rightTest: '完成'

      }
    }
  },
  mounted() {
    this.getInvoiceInfo()
  },
  methods: {
    callbackGo() {
      this.$router.go(-1)
    },
    linkTo: function(t) {
      if (t === 1) {
        this.$router.push({
          path: '/invoiceSon',
          query: {
            type: t
          }
        })
      } else if (t === 22) {
        this.$store.commit('isEmail', this.list.email)
        this.$router.push({
          path: '/invoiceTest',
          query: {
            type: t
          }
        })
      }
    },
    //  list
    getInvoiceInfo: function() {
      const data = {}
      invoiceInfo(data).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data
        }
      })
    },
    // 点击右边完成 回调
    callbackRefresh() {
    }
  }
}
</script>

<style scoped lang="less">
.invoice_box {
  background: #f6f6f6;
  text-align: left;

  .invoice_box_span {
    color: red;
    margin: 12px;
    font-size: 13px;
  }

  .top {
    margin-top: 12px;
  }

  .invoice_box_list {
    width: 100%;
    height: 42px;
    background: #fff;

    .invoice_box_title {
      height: 42px;
      margin: 0 0 0 12px;
      line-height: 42px;
      font-weight: 400;
      font-size: 14px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;

      .invoice_box_l {
        float: left;
        min-width: 100px;
        display: block;

      }

      .invoice_box_r {
        float: left;

        em {
          padding: 2px 6px;
          border: 1px solid rgba(0, 0, 0, .3);
          border-radius: 4px;
          color: rgba(0, 0, 0, .3);
          margin-left: 32px;
          font-size: 13px;
          font-style: normal;
        }
      }

      span {
        padding: 2px 6px;
        border: 1px solid rgb(248, 7, 7);
        border-radius: 4px;
        color: rgb(214, 15, 15);
        font-size: 13px;
      }

      em {
        padding: 2px 6px;
        border: 1px solid rgba(0, 0, 0, .3);
        border-radius: 4px;
        color: rgba(0, 0, 0, .3);
        font-size: 13px;
        font-style: normal;
        margin-right: 12px;
      }

      em.em {
        border: 1px solid rgb(248, 7, 7);
        color: rgb(214, 15, 15);
      }

      img {
        float: right;
        width: 7px;
        margin-top: 13px;
        margin-right: 12px;
      }

      i {
        font-style: normal;
        font-size: 14px;
      }

      .span {
        color: #666;
        font-size: 13px;
        border: none;
      }
    }

    .no {
      border: none;
    }
  }
}

</style>
